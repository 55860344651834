nav {
  position: relative;
}

.nav__toggle {
  display: none;

  & + label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    font-size: 0.1rem;

    & > span {
      display: block;
      width: 1rem;
      height: 2px;
      background-color: var(--clr-white);
      transition: all 0.3s ease-in-out;
      transform-origin: center center;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 1rem;
        height: 2px;
        background-color: var(--clr-white);
        transform-origin: center center;
        transition: all 0.3s ease-in-out;
      }
  
      &::before {
        transform: translateY(-6px);
        
      }
  
      &::after {
        transform: translateY(4px);
      }
    }

    + .menu {
      position: absolute;
      top: 100%;
      right: -1.5rem;
      max-height: 0px;
      overflow: hidden;

      background-color: var(--clr-primary-700);

      transition: all 0.3s ease-in-out;

      z-index: 10;

      & > ul {
        padding-top: 2rem;
        padding-right: 3rem;
        padding-bottom: 2.5rem;
        padding-left: 3rem;
      }

      + .overlay {
        display: none;

        position: fixed;
        inset: 0;
        background-color: hsla(224, 67%, 35%, 0.2);

        z-index: -1;
      }
    }
  }

  &:checked + label {
    & > span {
      rotate: 225deg;

      &::before {
        transform: translateY(0);
        rotate: 90deg;
      }
  
      &::after {
        transform: translateY(-2px) rotateZ(90deg);
      }
    }

    & + .menu {
      max-height: 100vh;

      & + .overlay {
        display: block;
      }
    }
  }
}

.nav--main {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;

  & .menu {
    & > ul {
      display: flex;
      flex-direction: column;
      list-style: none;

      gap: 1.5rem;

      & li {
        margin-bottom: 0;
      }
  
      & a {
        color: var(--clr-white);
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.1;
        text-decoration: none;
      }
  
    }
  }

}

@media (min-width: 1024px) {
  .nav__toggle {
    & + label,
    & + label + .menu + .overlay {
      display: none;
    }

    & + label + .menu {
      position: static;
      max-height: 100vh;
      background-color: transparent;

      & > ul {
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
      }
    }
  }

  .nav--main {
    flex-direction: row;

    & .menu {
      margin-right: 0;
      margin-left: auto;

      & ul {
        flex-direction: row;
        align-items: center;
        margin-left: auto;

        gap: 2.86rem;

        & a {
          font-size: 0.77rem;
        }
      }
    }

    & .btn {
      margin-left: 4.1rem;
      padding-right: 2.69rem;
      padding-left: 2.69rem;
    }
  }
}

.nav--footer {
  & ul {
    list-style: none;
  }

  & li + li {
    margin-top: 1.5rem;
  }

  & a {
    color: var(--clr-white);
    font-size: 0.77rem;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: none;
  }
}