
@import '../layout/structure';
@import '../layout/header';
@import '../layout/navigation';
@import '../layout/buttons';
@import '../layout/footer';

@import '../layout/syntax';

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

main code {
  padding: 2px 4px;
  border-radius: 6px;
  background-color: rgba(175, 184, 193, 0.2);
}

pre code {
  padding: 0px;
  background-color: var(--white);
}

pre {
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

.post {

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  table, th, td {
    border: 0.1rem solid var(--clr-primary-700);
    margin-bottom: 2rem;
  }

  th, td {
    padding-top: 0.2rem;
    padding-right: 0.5rem;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;
  }

  th {
    font-weight: 600;
  }

  strong {
    font-weight: 600;
  }
}