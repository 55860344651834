.header {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-primary-700);

  z-index: 10;

  & .logo {
    order: 1;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: var(--clr-white);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;

    & > img {
      width: 25px;
      height: auto;
    }

    & > span {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .header {
    height: 4.5rem;

    & .logo {
      order: 0;

      & > span {
        display: block;
      }
    }
  }
}