main {
  line-height: 1.2;
}

h1 {
  margin-bottom: 3rem;
  color: var(--clr-primary-700);
  font-size: 2rem; 
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: var(--clr-primary-700);
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--clr-primary-700);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.2;
}

h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--clr-primary-700);
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
}

p {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  /*line-height: 1.2;*/
}

ol {
  list-style-type: decimal;
  margin-left: 1rem;

  & li {
    margin-bottom: 0.5rem;
  }
}

ul {
  list-style-type: disc;
  margin-left: 1rem;

  & li {
    margin-bottom: 0.5rem;
  }
}

a {
  color: var(--clr-primary-700);
  /*font-size: 0.9rem;*/
  font-weight: 400;
  /*line-height: 1.2;*/
  text-decoration: underline;
}

@media (min-width: 768px) {
  h2 {
    margin-bottom: 1.5rem;
    text-align: left;
  }

  h3 {
    font-size: 1rem;
  }
}