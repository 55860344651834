
@import '../components/accordion';
@import '../components/hero';
@import '../components/tabs';
@import '../components/readmore';

@import '../components/news';
@import '../components/consulting';
@import '../components/info';

@import '../components/posts';
@import '../components/licensing_chart';