
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media (min-width: 1024px) {
  .container {
    width: #{"min(100%, 65rem)"};
  }
}