
.hidden {display: none}
.grid {display: grid}
.block {display: block}
.flex {display: flex}


@media (min-width: 768px) {
  .md\:hidden {display: none}
  .md\:block {display: block}
  .md\:flex {display: flex}
  .md\:grid {display: grid}
}

@media (min-width: 1024px) {
  .lg\:hidden {display: none}
  .lg\:block {display: block}
  .lg\:flex {display: flex}
  .lg\:grid {display: grid}
}

.justify-center {justify-content: center}
.justify-end {justify-content: end}

.grid-columns-1 {grid-template-columns: minmax(0, 1fr)}
.grid-columns-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}

@media (min-width: 768px) {
  .md\:grid-columns-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}
}

@media (min-width: 1024px) {
  .lg\:grid-columns-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}
}

.col-span-2 {grid-column: span 2 / span 2}

@media (min-width: 1024px) {
  .lg\:col-span-2 {grid-column: span 2 / span 2}
}

.gap-4 {gap: 1rem}
.gap-8 {gap: 2rem}
.gap-14 {gap: 3.5rem}
.gap-26 {gap: 6.5rem}

@media (min-width: 768px) {
  .md\:gap-4 {gap: 1rem}
  .md\:gap-8 {gap: 2rem}
  .md\:gap-14 {gap: 3.5rem}
  .md\:gap-26 {gap: 6.5rem}
}

@media (min-width: 1024px) {
  .lg\:gap-4 {gap: 1rem}
  .lg\:gap-8 {gap: 2rem}
  .lg\:gap-14 {gap: 3.5rem}
  .lg\:gap-26 {gap: 6.5rem}
}

.flex-row {flex-direction: row}
.flex-column {flex-direction: column}

@media (min-width: 768px) {
  .md\:flex-row {flex-direction: row}
  .md\:flex-column {flex-direction: column}
}

@media (min-width: 1024px) {
  .lg\:flex-row {flex-direction: row}
  .lg\:flex-column {flex-direction: column}
}

.order-0 {order: 0}
.order-1 {order: 1}

@media (min-width: 1024px) {
  .lg\:order-0 {order: 0}
  .lg\:order-1 {order: 1}
}

.mt-4 {margin-top: 1rem}
.mt-6 {margin-top: 1.5rem}
.mt-24 {margin-top: 6rem}
.mt-26 {margin-top: 6.5rem}

.mb-0 {margin-bottom: 0}
.mb-4 {margin-bottom: 1rem}
.mb-6 {margin-bottom: 1.5rem}
.mb-8 {margin-bottom: 2rem}
.mb-16 {margin-bottom: 4rem}

@media (min-width: 1024px) {
  .lg\:mb-0 {margin-bottom: 0}
  .lg\:mb-4 {margin-bottom: 1rem}
  .lg\:mb-6 {margin-bottom: 1.5rem}
  .lg\:mb-8 {margin-bottom: 2rem}
  .lg\:mb-16 {margin-bottom: 4rem}
}

.px-12 {
  padding-right: 3rem;
  padding-left: 3rem;
}

@media (min-width: 1024px) {
  .lg\:px-12 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.w-full {width: 100%}

.w-1\/2 {width: 50%}

.w-1\/3 {width: calc(100% / 3)}
.w-2\/3 {width: calc((100% / 3) * 2)}

.w-5\/6 {width: calc((100% / 6) * 5)}

@media (min-width: 768px) {
  .md\:w-full {width: 100%}

  .md\:w-1\/2 {width: 50%}

  .md\:w-5\/6 {width: calc((100% / 6) * 5)}
}

@media (min-width: 1024px) {
  .lg\:w-full {width: 100%}

  .lg\:w-1\/2 {width: 50%}

  .lg\:w-5\/6 {width: calc((100% / 6) * 5)}
}

.list-none {
  list-style-type: none;
  margin-left: 0rem;
}

.text-sm {font-size: 0.75rem}
.text-nm {font-size: 1rem}
.text-lg {font-size: 1.15rem}

.text-center {text-align: center}

.leading-5 {line-height: 1.5}

.semibold {font-weight: 600}

.uppercase {text-transform: uppercase}

@media (min-width: 1024px) {
  .lg\:text-sm {font-size: 0.75rem}
  .lg\:text-nm {font-size: 1rem}
  .lg\:text-lg {font-size: 1.15rem}
}